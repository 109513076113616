import React from "react";
import { AppBar, Container, Toolbar, Typography } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme.js';

export default function Footer() {
    return (
      <ThemeProvider theme={theme}>
        <AppBar position="static" style={{ background: theme.palette.secondary.main }}>
          <Container >
            <Toolbar>
              <Typography variant="body1" 
                style={{
                  textAlign:'center',
                  color:theme.palette.primary.main
                }}
              >
              Copyright© BIORSAF SRL Innovativa - Sviluppo e Design 
              <span>&nbsp;&nbsp;</span>
              <a
                href='https://rexolcom.com'
                target='_blank'
                rel="noopener noreferrer"
                style={{color: theme.palette.link.main}}
              >
                ReXoLcom 
              </a>
              <span>&nbsp;&nbsp;</span>

              Srl Uninominale
              </Typography>
              <span>&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;</span>
              <a 
                href='https://bsfood.biorsaf.it/condizioni-generali-uso-BS-FOOD.pdf' 
                target='_blank'
                rel="noopener noreferrer"
                style={{color: theme.palette.link.main}}
              >
                Condizioni D'uso  
              </a>
              <span>&nbsp;&nbsp;</span>
              <a 
                href='https://biorsaf.it/privacy-policy/'
                target='_blank'
                rel="noopener noreferrer"
                style={{color: theme.palette.link.main}}
              >
                Privacy Policy  
              </a>
              <span>&nbsp;&nbsp;</span>
              <a 
                href='https://biorsaf.it/cookie-policy/'
                target='_blank'
                rel="noopener noreferrer"
                style={{color: theme.palette.link.main}}
              >
                Cookie policy  
              </a>
              <span>&nbsp;&nbsp;</span>
              <a 
                href='https://bsfood.biorsaf.it/Utilizzo-pannello-di-controllo-BSFOOD.pdf'
                target='_blank'
                rel="noopener noreferrer"
                style={{color: theme.palette.link.main}}
              >
                Guida Piattaforma
              </a>
              <span>&nbsp;&nbsp;</span>
              <a 
                href='https://bit.ly/bsfoodticket'
                target='_blank'
                rel="noopener noreferrer"
                style={{color: theme.palette.link.main}}
              >
                Segnalazioni
              </a>
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    )
}